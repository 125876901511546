/* Mains */
@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-Light.otf);
  font-weight: 100;
}

@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-Bold.otf);
  font-weight: bold;
}

/* Italics */

@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-Italic.otf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-BoldItalic.otf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-LightItalic.otf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: diGalloInter;
  src: url(./assets/font/diGalloInter-MediumItalic.otf);
  font-weight: 500;
  font-style: italic;
}




:root {
  /*Tabor*/
  --color-primary: #005748; /*rgb(0,87,72) */
  --color-primary-transparency: rgba(0, 87, 72, 0.6); /* u.a. für Homescreen Overlay */
  --color-primary-hover: #33796d; /* 20% heller von Primary https://maketintsandshades.com/ */
  --color-secondary: #99bcb6; /* 70% heller von Primary? */
  --color-secondary-hover: #7a9692; /* 30% dunkler von secondary? */
  --color-text: #000;
  --color-text-dark: #fff;

  /* Global */
  --color-footer-bg-light: #e5e5e5;
  --color-footer-bg-dark: #b2b2b2;
  --border-radius: 20px;


  /*  BOOTSTRAP  */
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;  
  --bs-focus-ring-color: var(--color-primary);
  --bs-form-valid-color: #198754;
  --bs-border-radius: var(--border-radius);
  --bs-primary-text-emphasis: var(--color-primary);
  --bs-primary-bg-subtle: #fff;
  --bs-accordion-border-color: #fff;
  --bs-border-width: 1px;
  --bs-border-color: var(--color-primary);
  --bs-blue: var(--color-primary);
  --bs-btn-color: var(--color-primary);
  --bs-body-font-weight: 100;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.fw-bold {
  font-weight: 500 !important;
}

/*Cheap fix für falsche Border bei Accordions*/
.accordion-button:not(.collapsed) {
    box-shadow: inset 0 calc(0 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}


/*Form Styling*/
.form-control, .form-select{
  transition: 500;
  border-radius: 150px;
}

.form-select{
  margin-bottom: 15px;
}

.form-control:focus, .betriebActive{
  box-shadow: 0 0 0 2px var(--color-primary-transparency);
  border: none; 
}

.betriebActive{
  color: white;
  background: -moz-linear-gradient(90deg, var(--color-primary) 42%, var(--color-secondary) 100%);
  background: webkit-linear-gradient(90deg, var(--color-primary) 42%, var(--color-secondary) 100%);
  background: linear-gradient(90deg, var(--color-primary) 42%, var(--color-secondary) 100%);
}

textarea.form-control{
  border-radius: 30px;
}

.btn-outline-primary {
  --bs-btn-active-bg: var(--color-primary);
  --bs-btn-active-border-color: var(--color-primary);
}

.btn-check + .btn:hover {
  --bs-btn-active-bg: var(--color-primary-hover);
  --bs-btn-active-border-color: var(--color-primary-hover);
    color: var(--color-text-dark);
    background-color: var(  --color-primary-hover);
    border-color: var(  --color-primary-hover);
}

.form-check-input:checked + .btn:hover  {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.form-check-input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 0.25rem var(--color-primary-transparency);
}

.form-switch .form-check-input:focus {
  background-image: url(
  "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

/*Stelleninserat Betriebe Filter Style*/

.fadeNav {
  top: -80px !important;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  font-family: "diGalloInter", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}

p{
  white-space: pre-line;
  margin-bottom: 25px;
}

a {
  text-decoration: none;
  color: unset;
}

#navBarDesktop .active{
  color: var(--color-primary);
}

#navBarDesktop .active::before{
  transform-origin: left;
  transform: scaleX(1);
}

h1 {
  color: var(--color-primary);
  margin-bottom: 30px;
}


h2 {
  margin-bottom: 30px;
}

h3 {
  margin-bottom: 10px;
}

.offcanvas-body .active{
  background-color: var(--color-primary);
	color: var(--color-text-dark);
 }

.offcanvas-body {
  padding: 0;
}

/*Mapbox*/
.mapboxgl-map {
  border-radius: var(--border-radius);
}


.spacing200{
  height: 200px;
  width: 100%;
}

.spacing100{
  height: 100px;
  width: 100%;
}

.spacing50{
  height: 50px;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .container {
    padding-right: calc(var(--bs-gutter-x) * 1);
    padding-left: calc(var(--bs-gutter-x) * 1);
  }

  .spacing200{
    height: 100px;
    width: 100%;
  }
  
  .spacing100{
    height: 50px;
    width: 100%;
  }

  .spacing50{
    height: 25px;
    width: 100%;
  }
}

@media only print {
  * {
    print-color-adjust:exact;
    font-size: 0.97em;
  } 

  h1, h2, h3, h4, h5 {
    break-after: avoid;
    hyphens: auto;
    overflow-wrap: break-word;
  }

  .container {
    width: 100%;
    margin-left: 50px;
    margin-right: none;


  }
}

.offcanvas-start{
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}