.wrapper {
	background-color: #E5E5E5;
	padding-top: 12.5em;
}

.container {
	width: 90%;
	margin-left: 5%;
}

.socialIcons {
	display: inline-flex;
	text-decoration: none;
	padding: 5px 5px 5px 5px;
	background-color: var(--color-primary);
	margin: 10px 5px 0px 0px;
	border-radius: 5px;
	transition: 0.3S ease;
}

.socialIcons:hover *{
	color: var(--color-primary);
}

.socialIcons:hover {
	background-color: white;
}

.footerAddress{
	margin-top: 20px;
}

.footerRow{
	display: block;
	text-decoration: none;
	color: var(--color-text);
	padding-bottom: 10px;
	transition: 0.3S ease;
}

a.footerRow:hover{
	color: var(--color-primary);
}

.footerItem{
	text-decoration: none;	
	color: var(--color-text);
	display: block;
	padding-bottom: 6px;
	transition: 0.3S ease;
}

a.footerItem:hover{
	color: var(--color-primary);
}

.footerLogo{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 0px;
	margin-top: 30px;
}

.footerLegal{
	width: 100%;
	display: flex;
	background-color: #C7C7C7;
	margin: 50px 0 0 0;
	padding: 20px 0 20px 0;
	justify-content: center;
	gap: 10px;
}

.footerLegalItem{
	text-decoration: none;
	font-size: 0.8rem;
	color: #4c4c4c;
	transition: 0.2S;
}

.footerLegalItem:hover{
	font-weight: 500;
}

@media (max-width: 770.00px) {
	.footerItems{
		border-top: solid 1px rgb(200, 200 ,200);
		padding: 24px 0 24px 0;
	}

	.footerCompany{
		margin: 0;
		padding: 0 12px 24px 12px;
	}

	.footerItem{
		padding: 4px 12px 4px 12px;
	}

	.footerRow{
		padding: 6px 12px 6px 12px;
	}

}

@media only print {
	.wrapper {
		margin-top: 50px;
		padding-top: 30px;
		background-color: #fff;
		border-top: 1px solid black;
	}
	.footerRow, .footerItem, .socialIcons, .footerItems {
		display: none;
	}

	.footerLegal {
		display: none;
	}

	.footerLogo {
		align-items: end;

	}

	.container > * {
		height: 0;
	}
}
