.navContainer {
	width: 80vw;
	margin-left: auto;
	margin-top: 20px;
	border-radius: 100px;
	height: 55px; 
	margin-right: auto;
	box-shadow: 1px 2px 7px #aaa;
	display: flex;
	justify-content: space-between;
	padding: 0 15px 0 0;
	position: fixed;
	background-color: #fff;
	margin-left: 10vw;
	z-index: 999;
	transition: top 0.3s;
	top: 0;
}

.logo {
	padding-top: 5px;
	padding-left: 5px;
	display: flex;
}

.navItems {
	display: inline-flex;
	justify-content: right;
	margin-top: auto;
	margin-bottom: auto;
	height: 100%;
	padding: 0 0px 0 0;
	width: 100%;
	align-items: center;
}

.navItem, .navItemBurger {
	color: var(--color-text);
	font-weight: 400;
	text-decoration: none;
	position: relative;
	margin-left: calc(5px + 1vw);
}

.navItem::before {
	content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background-color: var(--color-secondary);
  bottom: -2px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.navItem:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}


/* Mobile Nav  */
.mobileBurger {
	padding: 0;
	display: inline-flex;
	justify-content: flex-end;
	margin-top: auto;
	margin-bottom: auto;
	padding-right: 5px;
	display: none;
}

.navMobileItem{
	display: block;
	text-decoration: none;
	color: var(--color-text);
	font-size: 1.2rem;
	width: 100%;
	position: relative;
	border-bottom: 1px solid var(--color-secondary);
	padding: 16px 16px;
	font-weight: 400;
}

.navMobileItem:first-child{
	padding-top: 24px;
}

.navMobileItem:hover{
	background-color: var(--color-primary-hover);
	color: var(--color-text-dark);
 }

 .mobileNav{
 	margin-top: 50px;
 }

 .languageButton{
	margin-left: 1em;
 }

 .languageButtonTrigger{
	background-color: transparent;
	border: none;
 }

.languageMenu{
	width: auto;
	min-width: 0!important;
	padding: 10 0;
	border-radius: 0 0 10px 10px;
	border: none;
	right: 0px;
}

.languageMenu li{
	cursor: pointer;
}

.languageMenu a:hover{
	color: var(--color-primary-hover)!important;
}

@media only screen and (max-width: 1020px) {
	.mobileBurger {
	display: inline-flex;
	}

	.navItems {
	display: none;
	}

	.navItem, .navItemBurger {
	padding: 0;
	}
}

@media only screen and (max-width: 575px) {
	.navContainer {
		width: calc(100% - 48px);
		margin: 24px 24px;
	}
  
}

@media only print {
	.navContainer {
		display: none;
	}
}
