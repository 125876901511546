:root{
	--button-height: 44px;
	--border-width: 2px;
}

svg{
	margin: 0;
}

.button, .textSpan{
	transition: all .3s ease-in-out;
}

.iconCircle{
	transition: all .29s ease-in-out;
}

.icon, .icon svg, .dummyCircle {
	width: var(--button-height);
}

.button {
	text-decoration: none;
	border: var(--border-width) solid var(--color-primary);
	border-radius: 100px;
	color: var(--color-primary);
	display: inline-flex;
	height: var(--button-height);
	width: auto;
	padding: 0;
	position: relative;
	background-color: white;
	background: linear-gradient(to right, var(--color-primary) 51%, white 49%);
    background-size: 200% 100%;
	background-position:right bottom;
	box-sizing: unset;
	margin-right: 10px;
	margin-bottom: 10px;
	text-align: unset;
	font-weight: 400;
	letter-spacing: .2px;
}

.iconCircle {
	border: var(--border-width) solid var(--color-primary);
	border-radius: 100px;
	display: inline-flex;
	height: var(--button-height);
	position: absolute;
	margin-top: calc(0px - var(--border-width));
	left: calc(0px - var(--border-width));
	right: auto;
	order: 2;
	background-color: white;
	z-index: 2;
	box-sizing: unset;
}

.icon {
	padding: 0;
	margin: 0;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
	text-align: center;
}

.icon svg {
	height: 100%;
	width: 60%;
}

.dummyText {
	margin: auto 10px auto 10px;
	display: inline-flex;
	opacity: 0;
}

.textSpan{
	position: absolute;
	right: 10px;
	top: 12px;
	bottom: 0;
	z-index: 1;
	height: 100%;
	padding: 0;
	order: 1;
	line-height: initial;
}

.button:hover {
	background-position:left bottom;
	border-color: ;
}

.button:hover span{
	color: white;
}

.button:hover .iconCircle{
	margin-left: calc(100% - var(--button-height));
	webkit-box-shadow: -8px 0px 0px 0px var(--color-primary);
  	-moz-box-shadow: -8px 0px 0px 0px var(--color-primary);
 	box-shadow: -8px 0px 0px 0px var(--color-primary);
}

.button:hover .textSpan{
	margin-right: var(--button-height);
}

@media only print {
	.button {
		display: none;
	}
}